import React, { type ReactNode } from 'react';
import { useMedia } from 'react-use';
import { X as CloseIcon } from '@hungryroot/icons';
import {
  ModalContent,
  ModalOverlay,
  Text,
  Heading,
  HeadingVariant,
  Button,
  ButtonSizeVariant as SizeVariant,
  ButtonStyleVariant as StyleVariant,
  ButtonWidthVariant as WidthVariant,
  ButtonProminenceVariant as ProminenceVariant,
} from '@hungryroot/ramen';
import { BreakpointQueryLarge } from '@hungryroot/tokens';

import styles from './Modal.module.css';

export type Props = {
  backgroundImage: ReactNode;
  onClose: () => void;
};

export function ModalExitIntentModal({ onClose, backgroundImage }: Props) {
  const isDesktop = useMedia(BreakpointQueryLarge);
  const closeButton = (
    <button onClick={onClose} aria-label="Close Modal" className={styles.close}>
      <CloseIcon />
    </button>
  );

  const backgroundImageElement = (
    <div className={styles['image-container']}>{backgroundImage}</div>
  );

  return (
    <ModalOverlay
      className={styles.container}
      onDismiss={onClose}
      isOpen={true}
    >
      {isDesktop === false && (
        <>
          {closeButton}
          {backgroundImageElement}
        </>
      )}

      <ModalContent
        ariaLabel="Before you go 30% off + get your choice of free gift."
        className={styles.content}
      >
        {isDesktop === true && (
          <>
            {closeButton}
            {backgroundImageElement}
          </>
        )}
        <div className={styles['text-content']}>
          <Heading
            className={styles['before-you-go']}
            variant={HeadingVariant.H6}
          >
            Before you go...
          </Heading>

          <Heading variant={HeadingVariant.H3} className={styles.discount}>
            30% off
          </Heading>
          <Text className={styles['free-gift-copy']}>
            + get your choice of free gift.
          </Text>
          <Button
            buttonType="link"
            to="/quiz/hello/"
            size={SizeVariant.Large}
            style={StyleVariant.Muted}
            prominence={ProminenceVariant.Primary}
            width={WidthVariant.FullWidth}
            textPrimary="Take The Quiz"
          />
        </div>
      </ModalContent>
    </ModalOverlay>
  );
}
